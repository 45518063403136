'use client';

import React, { useContext } from 'react';
import { GlobalsContext } from '@/providers/Globals';
import classes from './index.module.scss';
import { RichText } from '@/components/RichText';

export const ErrorMessage = () => {
  const { notFoundContent: context } = useContext(GlobalsContext) || {};
  const heading = context?.heading || '404';
  const description = context?.description;

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <h1 className={classes.heading}>{heading}</h1>

        <div className={classes.text}>{description && <RichText content={description} />}</div>
      </div>
    </div>
  );
};
